import { TRIP_SET, TRIP_INIT } from '../constants/actionTypes';

const INITIAL_STATE = {
  title: "",
};

// SET
const applySetTrip = (state, action) =>
  action.trip;

// INIT
const applyInitTrip = (state, action) =>
  INITIAL_STATE;

function tripReducer(state = INITIAL_STATE, action){
  switch(action.type) {
    case TRIP_SET: {
      return applySetTrip(state, action);
    }
    case TRIP_INIT: {
      return applyInitTrip(state, action);
    }
    default: return state;
  }
}

export default tripReducer;
