import { RECEIPT_SET, RECEIPT_INIT } from '../constants/actionTypes';

const doSetReceipts = receipts =>({
  type: RECEIPT_SET,
  receipts: receipts
});

const doInitReceipts = () => ({
  type: RECEIPT_INIT,
});

export {
  doSetReceipts,
  doInitReceipts,
}
