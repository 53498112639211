// Author Tatsuya Tsubakimoto. Created 2020/09/27
// HOC to obtain Member data. this starts listenning to realtime database.
// Also provides data control functions for members.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router';

// Actions
import { doSetMembers } from '../../actions/member';


const withMemberData = Component => {
  class withMemberData extends React.Component {
    constructor(props){
      super(props);
      this.onCreateMember = this.onCreateMember.bind(this);
      this.onEditMember = this.onEditMember.bind(this);
      this.onRemoveMember = this.onRemoveMember.bind(this);
      this.state = {
        tripId: this.props.match.params.id,
      }
    }

    componentDidMount(){
      // Starts listenning to realtime database and sets DB data to Redux store
      this.props.firebase.members(this.state.tripId).on('value', snapshot => {
        const memberObject = snapshot.val();
        if(memberObject){
          const memberList = Object.keys(memberObject).map(key => ({
            ...memberObject[key],
            uid: key
          }));
          this.props.onSetMembers(memberList);
        } else {
          this.props.onSetMembers([]);
        }
      });
    }

    componentWillUnmount(){
      // Off listennning to realtime database
      this.props.firebase.members(this.state.tripId).off();
    }
    onCreateMember() {
      this.props.firebase.members(this.state.tripId).push({
        name: '',
      });
    }
    onEditMember(member){
      const { uid, ...memberSnapshot } = member;
      this.props.firebase.member(this.state.tripId, uid).set({
        ...memberSnapshot
      });
    }
    onRemoveMember(uid){
      this.props.firebase.member(this.state.tripId, uid).remove();
    }
    render() {
      return (
        <Component {...this.props}
          onCreateMember={ this.onCreateMember }
          onEditMember={ this.onEditMember }
          onRemoveMember={ this.onRemoveMember }
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetMembers: members => dispatch(doSetMembers(members)),
  });

  return compose(
    withRouter,
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(withMemberData);
};

export default withMemberData;
