// Author Tatsuya Tsubakimoto. Created 2020/08/30
//Represents a recode of receipt information

import React from 'react';
import { connect } from 'react-redux';

// Components
import Button from '../common/Button';

// SASS
import '../../constants/css/ReceiptlistItem.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const MemberListItem = ({ member, onEditMember, onRemoveMember }) =>
  <div className="item">
    <form>
      <input
        className="name"
        type="text"
        value={member.name}
        placeholder="名前"
        onChange={(event) => onEditMember({...member, name: event.target.value})}
        // onChange={(event) => onChangeReceipt({...receipt, name: event.target.value})}
      />

      <div className="button_area">
        <Button
          size="small"
          color="warning"
          text={<FontAwesomeIcon icon="trash" />}
          onClick={() => onRemoveMember(member.uid)}
        />
      </div>
    </form>
  </div>

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberListItem);
