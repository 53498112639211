// Author Tatsuya Tsubakimoto. Created 2020/04/16
// Component for picture tag.
// Use this component everytime you add image on a page.

import React from 'react';

// const max_width_sm = '599px';
const min_width_md = '600px';
const min_width_lg = '1025px';
const SIZE = ['sm', 'md'];
const RESOLUTION = ['1x', '2x'];

const Picture = (props) => {
  let img = [];
  SIZE.map( (size, index_s) => {
    img[index_s] = [];
    RESOLUTION.map( (resolution, index_r) => {
      img[index_s][index_r] = require('../../resources/images/'+props.filename+'_'+size+'_'+resolution+'.'+props.extention);
    });
  });
  return (
    <picture className={props.classname}>
      <source media={"(min-width: "+min_width_md+")"} srcSet={img[1][0] +' 1x, '+ img[1][1]+' 2x'} />
      <img className={props.imgclass} srcSet={img[0][0] +'1x, '+img[0][1] +' 2x'} alt={props.alt} />
    </picture>
  );
}

export default Picture;
