// Author Tatsuya Tsubakimoto. Created 2020/09/02
// First page to show for new user

import React from 'react';

// Components
import LandingPageBase from './LandingPageBase';
import Button from '../common/Button';
import Picture from '../common/Picture';

// SASS
import '../../constants/css/LandingPage.sass';

import money from '../../resources/images/money.svg';
import fishing from '../../resources/images/fishing.svg';
import battle from '../../resources/images/battle.svg';
import moneymonster from '../../resources/images/moneymonster.svg';
import smartphonel from '../../resources/images/smartphone_left1.svg';
import smartphoner from '../../resources/images/smartphone1.svg';
import receiptPage from '../../resources/images/receiptlistPage_md_2x.png';
import resultPage from '../../resources/images/resultPage_md_2x.png';


const LandingPage = ({ onCreateTrip }) =>
  <div className="wrapper_landing">
    <div className="landing">
      <div className="contents">
        <div className="contents__main">
          <img src={money} alt="A frog with money"/>
          <p className="contents__description">みんなで支払ったお金の精算ができるアプリです。登録不要ですぐに始められます。もちろん友達とシェアするのも簡単！！</p>
          <Button
            size='large'
            text='使ってみる'
            onClick={() => onCreateTrip()}
          />
        </div>
        <div className="contents__sub">
          <h1>精算くんって？</h1>
          <p className="description--spacetop">旅行やイベントで立て替えたお金の精算ってめんどくさいですよね。レシート集めて計算して誰が誰に何円って。</p>
          <img src={fishing} alt="fishing event"/>
          <p className="description--nospace">時にはレシート無くして、けんかしたりして。</p>
          <img src={battle} alt="fighting"/>
          <p className="description--nospace">精算くんはそんな心配を解決するためのアプリです。</p>
          <div className="contents__row image__screen">
            <Picture
              classname="margin-right"
              imgclass="border"
              filename="receiptlistPage"
              extention="png"
              alt="screen sample"
            />
            <Picture
              imgclass="border"
              filename="resultPage"
              extention="png"
              alt="screen sample"
            />
          </div>
        </div>
        <div className="contents__sub">
          <h1>無料 & ログイン不要！</h1>
          <p className="description--spacetop">無料で使えて登録も不要です。ちなみにログインするとちょっと便利な機能が使えます。</p>
          <img className="image--smaller" src={moneymonster} alt="money money money"/>
        </div>
        <div className="contents__sub">
          <h1>簡単シェア & リアルタイム更新</h1>
          <p className="description--spacetop">ボタン一つで友達とシェア！みんなで入力しよう！しかもリアルタイム更新なので二重入力は起きません。</p>
          <div className="contents__row image__share">
            <img className="image--smaller" src={smartphonel} alt="A frog sharing trip accountant"/>
            <img className="image--small" src={smartphoner} alt="A frog receiving trip accountant"/>
          </div>
        </div>
        <div className="contents__margin"></div>
        <Button
          size='large'
          text='使ってみる'
          onClick={() => onCreateTrip()}
        />
        <div className="contents__margin--large"></div>
        <footer>
          ©︎ 2020. TATSUYA TSUBAKIMOTO. ALL RIGHTS RESERVED.
        </footer>
      </div>
    </div>
  </div>


export default LandingPageBase(LandingPage);
