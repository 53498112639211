export const LANDING = "/";
export const TRIP = "/trip/:id";
export const TRIP_ACCESS = "/trip/";
export const RESULT = "/result/:id";
export const RESULT_ACCESS = "/result/";
export const GROUP = "/group/:id";
export const GROUP_ACCESS = "/group/";
export const BOARDLIST = "/boardlist/:id";
export const BOARDLIST_ACCESS = "/boardlist/";
export const TOP_URL = process.env.REACT_APP_APP_URL;
