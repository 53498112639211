import { combineReducers } from 'redux';
import receiptReducer from './receipt';
import memberReducer from './member';
import sessionReducer from './session';
import assetReducer from './asset';
import tripReducer from './trip';

const rootReducer = combineReducers({
  receiptState: receiptReducer,
  memberState: memberReducer,
  sessionState: sessionReducer,
  assetState: assetReducer,
  tripState: tripReducer,
});

export default rootReducer;
