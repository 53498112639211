// Author Tatsuya Tsubakimoto. Created 2020/09/26
//Represents a recode of Board information

import React from 'react';
import { connect } from 'react-redux';

// Components
import Button from '../common/Button';

// SASS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../constants/css/BoardlistItem.sass';

// Selectors
import { getAssets } from '../../selectors/asset';

// Common library
import { date } from '../../common';

// Routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const BoardlistItem = ({ key, asset }) =>
  <Link to={ROUTES.TRIP_ACCESS + asset.uid}>
    <div className="board__item">
      <span className="item__title">{ asset.title ? asset.title : 'No title'}</span>
      <div className="item__time">
        <span>最終更新: {asset.updatedAt ? date.getDatetime(asset.updatedAt) : '-'}</span>
        <span>作成: {date.getDatetime(asset.createdAt)}</span>
      </div>
    </div>
  </Link>


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoardlistItem);
