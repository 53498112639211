// Author Tatsuya Tsubakimoto. Created 2020/09/27
// HOC to obtain User data. this starts listenning to realtime database.
// Also provides data control functions for user.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withAuthentication } from '../Session';

// Actions
// import { doSetAssets } from '../../actions/asset';


const withAssetData = Component => {
  class withAssetData extends React.Component {
    constructor(props){
      super(props);
    }
    componentDidMount(){
      // Starts listenning to realtime database and sets DB data to Redux store
      this.props.onInitAssets();
      this.props.firebase.tripAssets(this.props.authUser.uid).on('value', snapshot => {
        const assetObject = snapshot.val();
        if(assetObject){
          Object.keys(assetObject).map(key => {
            // Adds trip information
            this.props.firebase.trip(key).on('value', tripSnapshot => {
              const tripObject = tripSnapshot.val();
              this.props.onSetAssets({
                  ...assetObject[key],
                  ...tripObject,
                  uid: key,
              });
            });
          });
        }
      });
    }
    componentWillUnmount(){
      // Off listennning to realtime database
      this.props.firebase.tripAssets(this.props.authUser.uid).off();
      this.props.firebase.trip().off();
    }
    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  const mapDispatchToProps = dispatch => ({
    onSetAssets: assets => dispatch({ type: 'ASSET_SET', assets: assets }),
    onInitAssets: assets => dispatch({ type: 'ASSET_INIT' }),
  });


  return compose(
    withAuthentication,
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(withAssetData);
};

export default withAssetData;
