import { MEMBER_SET, MEMBER_INIT } from '../constants/actionTypes';

const doSetMembers = members => ({
  type: MEMBER_SET,
  members: members
});

const doInitMembers = () => ({
  type: MEMBER_INIT,
});

export {
  doSetMembers,
  doInitMembers,
}
