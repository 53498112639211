// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Common header.

import React from 'react';

// Components
import AccountPopup from './AccountPopup';

// SASS
import '../../constants/css/Header.sass';
import '../../constants/css/common/common.sass';

//Images
import logo from '../../resources/images/logo.svg';

// Routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


const Header = () =>
  <header className="header">
    <div className="content__area">
      <div className="content empty">
      </div>
      <div className="title">
        <img src={logo} alt="logo"/>
        <Link to={ROUTES.LANDING}>
          <p>
            精算くん
          </p>
        </Link>

      </div>
      <div className="content">
        <AccountPopup />
      </div>
    </div>
  </header>


export default Header;
