// Author Tatsuya Tsubakimoto. Created 2020/08/30

import React from 'react';
import '../constants/css/App.sass';

// Components
import Header from './Header';
import LandingPage from './LandingPage';
import ReceiptsPage from './ReceiptsPage';
import ResultPage from './ResultPage';
import MembersPage from './MembersPage';
import BoardlistPage from './BoardlistPage';

// React router
import { Switch, Route } from 'react-router-dom';
import * as routes from '../constants/routes';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faPlus, faUser, faUserCircle, faClone } from '@fortawesome/free-solid-svg-icons';

library.add(faTrash, faPlus, faUser, faUserCircle, faClone);


const App = () =>
    <div className="app">
      <Header />
      <div className="app__contents">
        <Switch>
          <Route
            exact path={routes.LANDING}
            component={() => <LandingPage />}
          />
          <Route
            path={routes.TRIP}
            component={() => <ReceiptsPage />}
          />
          <Route
            path={routes.RESULT}
            component={() => <ResultPage />}
          />
          <Route
            path={routes.GROUP}
            component={() => <MembersPage />}
          />
          <Route
            path={routes.BOARDLIST}
            component={() => <BoardlistPage />}
          />
        </Switch>
      </div>
    </div>

export default App;
