const INITIAL_STATE = [];

const applySetAsset = ( state, action ) =>
  [...state, action.assets]

const applyInitAsset = ( state, action ) =>
  INITIAL_STATE;

function assetReducer(state = INITIAL_STATE, action){
  switch (action.type){
    case 'ASSET_SET':{
      return applySetAsset(state, action);
    }
    case 'ASSET_INIT':{
      return applyInitAsset(state, action);
    }
    default:{
      return state;

    }
  }
}

export default assetReducer;
