import { TRIP_SET, TRIP_INIT } from '../constants/actionTypes';

const doSetTrip = trip =>({
  type: TRIP_SET,
  trip: trip
});

const doInitTrip = () => ({
  type: TRIP_INIT,
});

export {
  doSetTrip,
  doInitTrip,
}
