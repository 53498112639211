import React from 'react';

import '../../constants/css/common/Button.sass';

const Button = ( props ) =>
  <button
    className={props.size + ' ' + props.color}
    type="button"
    onClick={event => props.onClick(event)}
  >
    { props.text }
  </button>


export default Button;
