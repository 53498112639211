import { MEMBER_SET, MEMBER_INIT } from '../constants/actionTypes';

const INITIAL_STATE = [];

// SET
const applySetMembers = (state, action) =>
  action.members;

// INIT
const applyInitMembers = (state, action) =>
  INITIAL_STATE;


function memberReducer(state = INITIAL_STATE, action){
  switch(action.type) {
    case MEMBER_SET: {
      return applySetMembers(state, action);
    }
    case MEMBER_INIT: {
      return applyInitMembers(state, action);
    }
    default: return state;
  }
}

export default memberReducer;
