// Returns all trips the user own
const getAssets = ({ assetState }) =>
  assetState;

const getAssetsByLastviewed = ({ assetState }) =>{
  let assetlist = getAssets({ assetState }).slice();
  let result = assetlist.sort((first, second) =>
    first.lastViewedAt < second.lastViewedAt ? 1 : -1
  );
  return result;
}

const getAssetsByUpdated = ({ assetState }) =>{
  let assetlist = getAssets({ assetState });
  let result = assetlist.sort((first, second) =>
    first.updatedAt < second.updatedAt ? 1 : -1
  );
  return result;
}

export {
  getAssets,
  getAssetsByUpdated,
  getAssetsByLastviewed,
}
