//RECEIPT
export const RECEIPT_SET = 'RECEIPT_SET';
export const RECEIPT_INIT = 'RECEIPT_INIT';

//MEMBER
export const MEMBER_SET = 'MEMBER_SET';
export const MEMBER_INIT = 'MEMBER_INIT';

//TRIP
export const TRIP_SET = 'TRIP_SET';
export const TRIP_INIT = 'TRIP_INIT';
