// Author Tatsuya Tsubakimoto. Created 2020/08/30
//Represents a recode of receipt information

import React from 'react';
import { connect } from 'react-redux';

// Components
import Button from '../common/Button';

// Selectors
import { getMembers } from '../../selectors/member';

// SASS
import '../../constants/css/ReceiptlistItem.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ReceiptlistItem = ({ key, receipt, members, onEditReceipt, onRemoveReceipt }) =>
  <div className="item">
    <form>
      <input
        className="name"
        type="text"
        value={receipt.name}
        placeholder="費用名"
        onChange={(event) => onEditReceipt({...receipt, name: event.target.value})}
      />
      <input
        className="price"
        type="number"
        value={receipt.price}
        placeholder="金額"
        onChange={(event) => onEditReceipt({...receipt, price: event.target.value})}

      />
      <select
        //Shows placeholder with gray text when paidby is empty
        value={receipt.paidby === '' || members.length === 0 ? '' : members.filter(member => member.uid === receipt.paidby)[0].uid}
        className={receipt.paidby === '' ? "paidby form_select-unmatch" : "paidby" }
        onChange={(event) => onEditReceipt({...receipt, paidby: event.target.value})}
      >
        { //Shows placeholder when paidby is empty
          receipt.paidby === '' ? <option className="form__select--placeholder" key={''} value={''} >支払者</option> : null }
        { //Shows members' name as options
          members.map(member =>
            <option key={member.uid} value={member.uid}> {member.name} </option>
        )}
      </select>
      <div className="button_area">
        <Button
          size="small"
          color="warning"
          text={<FontAwesomeIcon icon="trash" />}
          onClick={() => onRemoveReceipt(receipt.uid)}
        />
      </div>
    </form>
  </div>


const mapStateToProps = state => ({
  members: getMembers(state),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiptlistItem);
