import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.database();
    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    app.analytics();
  }

  trip = tripId => this.db.ref(`trips/${tripId}/`);
  trips = () => this.db.ref('trips');
  tripReceipt = tripId => this.db.ref(`tripReceipts/${tripId}/`);
  tripReceipts = () => this.db.ref('tripReceipts');

  receipt = (tripId, receiptId) => this.db.ref(`tripReceipts/${tripId}/receipts/${receiptId}`);
  receipts = tripId => this.db.ref(`tripReceipts/${tripId}/receipts`);
  member = (groupId, memberId) => this.db.ref(`groups/${groupId}/members/${memberId}`);
  members = groupId => this.db.ref(`groups/${groupId}/members`);
  group = groupId => this.db.ref(`groups/${groupId}/`);
  groups = () => this.db.ref('groups');

  user = userId => this.db.ref(`users/${userId}/`);
  tripAsset = (userId, tripId) => this.db.ref(`assets/${userId}/trips/${tripId}/`);
  tripAssets = userId => this.db.ref(`assets/${userId}/trips/`);
  groupAsset = (userId, groupId) => this.db.ref(`assets/${userId}/groups/${groupId}/`);


  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

};

export default Firebase;
