// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Page to manage receipt data

import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

// ROUTES
import { withRouter } from 'react-router-dom';

// HOC
import { withAuthentication } from '../Session';

const ReceiptsPageBase = Component => {
  class ReceiptsPageBase extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        showIcons: undefined
      };
      this.toggleShowIcons = this.toggleShowIcons.bind(this);
      this.copyURL = this.copyURL.bind(this);
    }
    componentDidMount(){
      if(this.props.authUser){
        // Starts listenning to realtime database and sets DB data to Redux store
        this.props.firebase.tripAsset(this.props.authUser.uid, this.props.match.params.id).update({
          lastViewedAt: new Date().getTime(),
        });
      }
    }
    // Toggles whether show share icons or not
    toggleShowIcons(){
      this.setState({ showIcons: !this.state.showIcons });
    }
    // Copys current URL to clipboard
    copyURL(){
      let currentURL = new String(process.env.REACT_APP_APP_URL + this.props.location.pathname);
      navigator.clipboard.writeText(currentURL).then(e => {
        alert('URLをコピーしました');
      });
    }
    render(){
      return (
        <Component
          {...this.props}
          localState={this.state}
          toggleShowIcons={this.toggleShowIcons}
          copyURL={this.copyURL}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withRouter,
    withAuthentication,
    connect(
      mapStateToProps
    ),
  )(ReceiptsPageBase);
};

export default ReceiptsPageBase;
