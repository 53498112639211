// Returns receipt information with paidby member name
const getReceipts = ({ receiptState }) =>
  receiptState;

// Calculates total amount of all the receipts
const getTotalPrice = ({ receiptState }) => {
  let result=0;
  receiptState.map(receipt => result+=Number(receipt.price));
  return result;
}

// Calculates average amount of all the receipts
const getAveragePrice = ({ receiptState, memberState }) =>
  Math.round( getTotalPrice({ receiptState }) / (memberState.length > 0 ? memberState.length : 1) );

// Returns list of negative benefit(paid amount - average price) of all members
const getListBenefit = ({ receiptState, memberState }) => {
  let paidlist = getListPaidAmount({ receiptState, memberState });
  let averagePrice = getAveragePrice({ receiptState, memberState })
  let list = [];
  paidlist.map(member =>
    list.push({...member, amount: member.amount - averagePrice})
  );
  return list;
}

// Returns list of paid amount of all members
const getListPaidAmount = ({ receiptState, memberState }) => {
  let list = [];
  memberState.map(member =>
    list.push({
      uid: member.uid,
      name: member.name,
      amount: getPaidAmount({receiptState, member}),
    })
  );
  return list;
}

// Calculates total paid amount of a member
const getPaidAmount = ({ receiptState, member }) => {
  let result = 0;
  receiptState.map(receipt => member.uid===receipt.paidby ? result+=Number(receipt.price) : null);
  return Math.round(result);
}


export {
  getReceipts,
  getTotalPrice,
  getAveragePrice,
  getListBenefit,
  getListPaidAmount,
}
