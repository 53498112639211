// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Page to manage receipt data

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withReceiptData, withMemberData, withTripData } from '../DBAccess';

// Components
import ReceiptsPageBase from './ReceiptsPageBase';
import ReceiptlistItem from './ReceiptlistItem';
import ReceiptsFooter from './ReceiptsFooter';
import Button from '../common/Button';
import ButtonFloat from '../common/ButtonFloat';
import { LineIcon } from 'react-share';

// Selectors
import { getReceipts } from '../../selectors/receipt';
import { getMembers } from '../../selectors/member';
import { getTrip } from '../../selectors/trip';

// SASS
import '../../constants/css/ReceiptsPage.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Images
import shareIcon from '../../resources/images/share.svg';

// Routes
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';


const ReceiptsPage = ({ match, location, receipts, members, trip, onEditReceipt, onCreateReceipt, onRemoveReceipt, onEditTriptitle, localState, toggleShowIcons, copyURL }) => {
  useEffect(() => {
    // Scrolls to bottom after data loading finished
    window.scrollTo({ top:99999999, behavior: 'smooth' })
  });
  return (
    <div className="wrapper">
      <div id="list_receipt">
      <div className="float_area_top">
        <h1>
          <form>
            <input
              className="title"
              type="text"
              value={trip.title}
              placeholder="イベントタイトル"
              onChange={(event) => onEditTriptitle(event.target.value)}
            />
          </form>
        </h1>
      </div>
        {(receipts || []).map((receipt, index) => {
          return (
            <ReceiptlistItem
              key={index}
              receipt={receipt}
              onEditReceipt={onEditReceipt}
              onRemoveReceipt={onRemoveReceipt}
            />
          );
        })}
        <Button
          size='large'
          text={<FontAwesomeIcon icon="plus" />}
          onClick={() => onCreateReceipt()}
        />
      </div>
      <div className="float_area">
        <div className="float_area--left">
          { members.length === 0 ? <div className="comment-popup">↓まずメンバーを登録しよう</div> : null }
          <Link to={ROUTES.GROUP_ACCESS + match.params.id}>
            <ButtonFloat
              size='large'
              text={<div><FontAwesomeIcon icon="user" /> メンバー</div>}
              onClick={() => null}
            />
          </Link>
        </div>
        <div className="float_area--right">
          <div className="icons">
            {localState.showIcons !== undefined &&
              <div className="slidein_icons">
                <a
                  className={localState.showIcons ? "line_button line_button--show" : "line_button line_button--hide"}
                  href={'https://line.me/R/msg/text/?'+ROUTES.TOP_URL+location.pathname}
                >
                  <LineIcon size={40} round />
                </a>
                <div className={localState.showIcons ? "copy_button copy_button--show" : "copy_button copy_button--hide"} onClick={event => copyURL()}>
                  <FontAwesomeIcon className="icon" icon="clone"/>
                </div>
              </div>
            }
            <div className="share_button">
              <img className="icon" src={shareIcon} alt="Share button" onClick={event => toggleShowIcons()}/>
            </div>
          </div>
        </div>
      </div>
      <ReceiptsFooter />
    </div>
  );
}



const mapStateToProps = state => ({
  receipts: getReceipts(state),
  members: getMembers(state),
  trip: getTrip(state),
});


export default compose(
  withReceiptData,
  withMemberData,
  withTripData,
  ReceiptsPageBase,
  connect(
    mapStateToProps,
  ),
)(ReceiptsPage);
