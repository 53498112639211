import { RECEIPT_SET, RECEIPT_INIT } from '../constants/actionTypes';

const INITIAL_STATE = [];

// SET
const applySetReceipts = (state, action) =>
  action.receipts;

// INIT
const applyInitReceipt = (state, action) =>
  INITIAL_STATE;


function receiptReducer(state = INITIAL_STATE, action){
  switch(action.type) {
    case RECEIPT_SET: {
      return applySetReceipts(state, action);
    }
    case RECEIPT_INIT: {
      return applyInitReceipt(state, action);
    }
    default: return state;
  }
}

export default receiptReducer;
