// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Footer for ResultPage

import React from 'react';

// Components
import FooterBase from '../common/FooterBase';

// Routes
import * as routes from '../../constants/routes';
import { withRouter } from 'react-router';

const ResultFooter = ({ match }) =>
  <FooterBase
    route={routes.TRIP_ACCESS + match.params.id}
    text='戻る'
  />

export default withRouter(ResultFooter);
