// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Footer for ListPage

import React from 'react';

// Components
import FooterBase from '../common/FooterBase';

// Routes
import * as routes from '../../constants/routes';
import { withRouter } from 'react-router';

const ReceiptsFooter = ({ match }) =>
  <FooterBase
    route={routes.RESULT_ACCESS + match.params.id}
    text='精算ページ'
  />

export default withRouter(ReceiptsFooter);
