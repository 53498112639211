// Author Tatsuya Tsubakimoto. Created 2020/09/27
// HOC to obtain Receipt data. this starts listenning to realtime database.
// Also provides data control functions for receipts.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router';

// Actions
import { doSetReceipts } from '../../actions/receipt';


const withReceiptData = Component => {
  class withReceiptData extends React.Component {
    constructor(props){
      super(props);
      this.onCreateReceipt = this.onCreateReceipt.bind(this);
      this.onEditReceipt = this.onEditReceipt.bind(this);
      this.onRemoveReceipt = this.onRemoveReceipt.bind(this);
      this.state = {
        tripId: this.props.match.params.id,
      }
    }
    componentDidMount(){
      // Starts listenning to realtime database and sets DB data to Redux store
      this.props.firebase.receipts(this.state.tripId).on('value', snapshot => {
        const receiptObject = snapshot.val();
        if(receiptObject){
          const receiptList = Object.keys(receiptObject).map(key => ({
            ...receiptObject[key],
            uid: key
          }));
          this.props.onSetReceipts(receiptList);
        } else {
          this.props.onSetReceipts([]);
        }
      });
    }

    componentWillUnmount(){
      // Off listennning to realtime database
      this.props.firebase.receipts(this.state.tripId).off();
    }
    onCreateReceipt() {
      this.props.firebase.receipts(this.state.tripId).push({
        name: '',
        price: '',
        paidby: ''
      });
      // Updates updatedAt on trip information
      this.props.firebase.trip(this.state.tripId).update({
        updatedAt: new Date().getTime(),
      });
    }
    onEditReceipt(receipt){
      const { uid, ...receiptSnapshot } = receipt;
      this.props.firebase.receipt(this.state.tripId, uid).set({
        ...receiptSnapshot
      });
      // Updates updatedAt on trip information
      this.props.firebase.trip(this.state.tripId).update({
        updatedAt: new Date().getTime(),
      });
    }
    onRemoveReceipt(uid){
      this.props.firebase.receipt(this.state.tripId, uid).remove();
      // Updates updatedAt on trip information
      this.props.firebase.trip(this.state.tripId).update({
        updatedAt: new Date().getTime(),
      });
    }
    render() {
      return (
        <Component {...this.props}
          onCreateReceipt={ this.onCreateReceipt }
          onEditReceipt={ this.onEditReceipt }
          onRemoveReceipt={ this.onRemoveReceipt }
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetReceipts: receipts => dispatch(doSetReceipts(receipts)),
  });

  return compose(
    withRouter,
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(withReceiptData);
};

export default withReceiptData;
