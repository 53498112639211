// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Page to show result of payment

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Components
import ResultFooter from './ResultFooter';

// Selectors
import { getListBenefit } from '../../selectors/receipt';
import { getTrip } from '../../selectors/trip';

// SASS
import '../../constants/css/ResultPage.sass';

// HOC
import { withReceiptData, withMemberData, withTripData } from '../DBAccess';


const ResultPage = ({ listBenefit, trip }) => (
  <div className="wrapper">
    <div className="result">
      {trip.title ? <h1 className="triptitle">{trip.title}</h1> : ""}
      <div className="result_take">
        <h1>もらう人</h1>
        <ul>
          {listBenefit.map(member =>
            member.amount > 0 ?
              <li key={member.uid} >{member.name}: { member.amount }円</li>
              : null
          )}
        </ul>
      </div>
      <div className="result_give">
        <h1>わたす人</h1>
        <ul>
          {listBenefit.map(member =>
            member.amount < 0 ?
              <li key={member.uid} >{member.name}: { member.amount * -1 }円</li>
              : null
          )}
        </ul>
      </div>
    </div>
    <ResultFooter />
  </div>
);


const mapStateToProps = state => ({
  listBenefit: getListBenefit(state),
  trip: getTrip(state),
});


export default compose(
  withReceiptData,
  withMemberData,
  withTripData,
  connect(
    mapStateToProps,
  ),
)(ResultPage);
