// Author Tatsuya Tsubakimoto. Created 2020/10/18
// HOC to obtain Trip data. this starts listenning to realtime database.
// Also provides data control functions for receipts.

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router';

// Actions
import { doSetTrip } from '../../actions/trip';


const withTripData = Component => {
  class withTripData extends React.Component {
    constructor(props){
      super(props);
      this.onEditTriptitle = this.onEditTriptitle.bind(this);
      this.state = {
        tripId: this.props.match.params.id,
      }
    }
    componentDidMount(){
      // Starts listenning to realtime database and sets DB data to Redux store
      this.props.firebase.trip(this.state.tripId).on('value', snapshot => {
        const tripObject = snapshot.val();
        this.props.doSetTrip(tripObject);
      });
    }

    componentWillUnmount(){
      // Off listennning to realtime database
      this.props.firebase.trip(this.state.tripId).off();
    }
    onEditTriptitle(title){
      // Updates title and updatedAt on trip information
      this.props.firebase.trip(this.state.tripId).update({
        title: title,
        updatedAt: new Date().getTime(),
      });
    }
    render() {
      return (
        <Component {...this.props}
          onEditTriptitle={ this.onEditTriptitle }
        />
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    doSetTrip: trip => dispatch(doSetTrip(trip)),
  });

  return compose(
    withRouter,
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(withTripData);
};

export default withTripData;
