// Author Tatsuya Tsubakimoto. Created 2021/04/23
// Logics for page to see available boards

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// HOC
import { withAssetData } from '../DBAccess/';
import { withAuthentication } from '../Session';
import { withCreateTrip } from '../DBAccess';

// Selectors
import { getAssetsByUpdated } from '../../selectors/asset';

// Actions
import { doInitReceipts } from '../../actions/receipt';
import { doInitMembers } from '../../actions/member';
import { doInitTrip } from '../../actions/trip';


const BoardlistPageBase = Component => {
  class BoardlistPageBase extends React.Component {
    constructor(props){
      super(props);
    }

    render(){
      this.props.initReceipts();
      this.props.initMembers();
      this.props.initTrip();
      return (
        <Component {...this.props}
        />
      );
    }
  }
  const mapStateToProps = state => ({
    assets: getAssetsByUpdated(state),
  });

  const mapDispatchToProps = dispatch => ({
    initReceipts: () => dispatch(doInitReceipts()),
    initMembers: () => dispatch(doInitMembers()),
    initTrip: () => dispatch(doInitTrip()),
  });

  return compose(
    withAssetData,
    withCreateTrip,
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(BoardlistPageBase);
}

export default BoardlistPageBase;
