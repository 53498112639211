// Author Tatsuya Tsubakimoto. Created 2021/04/23
// implements create trip as props

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withFirebase } from '../Firebase';

// Routes
import * as routes from '../../constants/routes';
import { withRouter } from 'react-router';

const withCreateTrip = Component => {
  class withCreateTrip extends React.Component {
    constructor(props) {
      super(props);
      this.onCreateTrip = this.onCreateTrip.bind(this);
    }
    onCreateTrip() {
      let uid = this.props.firebase.trips().push({
        createdAt: new Date().getTime(),
      }).getKey();
      this.props.firebase.tripReceipt(uid).set({
        createdAt: new Date().getTime(),
      });
      this.props.firebase.receipts(uid).push({
        name: '',
        price: '',
        paidby: ''
      });
      this.props.firebase.group(uid).set({
        createdAt: new Date().getTime(),
        saved: false,
      });
      if(this.props.authUser){
        this.props.firebase.tripAsset(this.props.authUser.uid, uid).set({
          lastViewedAt: new Date().getTime(),
        });
      }
      this.props.history.push(routes.TRIP_ACCESS + uid);
    }
    render() {
      return (
        <Component {...this.props}
          onCreateTrip={ this.onCreateTrip }
        />
      )
    }
  }

  const mapStateToProps = state => ({
  });

  return compose(
    withRouter,
    withFirebase,
    connect(
      mapStateToProps
    ),
  )(withCreateTrip);
}

export default withCreateTrip;
