import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { withFirebase } from '../Firebase';
import SignInGoogle from '../common/SignInGoogle';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Authentication
import { withAuthentication } from '../Session';

// Routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


// Popup for account button
const AccountPopup = ({ authUser }) =>
  <Popup
    trigger={<div className="menu-item"><FontAwesomeIcon icon={['fas', 'user-circle']} />  </div>}
    position="bottom right"
    on="click"
    closeOnDocumentClick="true"
    mouseLeaveDelay={300}
    mouseEnterDelay={0}
    contentStyle={{ padding: '0px', border: 'none' }}
    arrow={false}
  >
    { authUser ? <PopupAuth authUser={authUser}/> : <PopupNonAuth /> }
  </Popup>


// Contents on Account Popup while loged in
const PopupAuthBase = ({ authUser, firebase }) =>
  <ul className="popup__menu">
    <li className="popup__title"><FontAwesomeIcon className="popup__icon" icon={['fas', 'user-circle']} />{authUser.displayName}</li>
    {/* <li className="popup__item">モード切替</li> */}
    <Link to={ROUTES.BOARDLIST_ACCESS + authUser.uid}>
      <li className="popup__item">精算りれき</li>
    </Link>
    {/* <li className="popup__item">設定</li> */}
    <li className="popup__item" onClick={() => firebase.doSignOut()}>ログアウト</li>
  </ul>


// Contents on Account Popup while nobody loged in
const PopupNonAuthBase = ({ firebase }) =>
  <ul className="popup__menu">
    <li className="popup__title"><FontAwesomeIcon className="popup__icon" icon={['fas', 'user-circle']} />ゲスト</li>
    <li className="popup__item">モード切替</li>
    <li className="popup__item-empty"></li>
    <li className="popup__item">ログイン</li>
    <li className="popup__item"><SignInGoogle /></li>
  </ul>



const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const PopupAuth = withFirebase(PopupAuthBase);
const PopupNonAuth = withFirebase(PopupNonAuthBase);


export default compose(
  withAuthentication,
  connect(
    mapStateToProps
  ),
)(AccountPopup);
