import React from 'react';
import { compose } from 'recompose';

// Firebase
import { withFirebase } from '../Firebase';

// Images
import google_logo from '../../resources/images/btn_google_signin_dark_normal_web.png';

// Sign in button and logics for google login
class SignInGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Creates a user in your Firebase Realtime Database too
        return this.props.firebase
          .user(socialAuthUser.user.uid)
          .set({
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
          });
      })
      .then(() => {
        this.setState({ error: null });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  render() {
    return (
      <form className="login_button" onClick={this.onSubmit}>
        <img className="google_logo" src={google_logo} alt="Sign in with Google"/>
      </form>
    );
  }
}

export default compose(
  withFirebase,
)(SignInGoogle);
