import React from 'react';

import '../../constants/css/common/ButtonFloat.sass';

const ButtonFloat = ( props ) =>
  <button
    className={'button_float ' + props.size + ' ' + props.color}
    type="button"
    onClick={event => props.onClick(event)}
  >
    { props.text }
  </button>


export default ButtonFloat;
