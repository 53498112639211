// Author Tatsuya Tsubakimoto. Created 2020/09/26
// Represents a page to see available boards
import React from 'react';

// Components
import BoardlistItem from './BoardlistItem';
import BoardlistPageBase from './BoardlistPageBase';
import Button from '../common/Button';

// SASS
import '../../constants/css/BoardlistPage.sass';
import '../../constants/css/common/common.sass';


const BoardlistPage = ({ assets, initReceipts, initMembers, initTrip, onCreateTrip }) =>
    <div id="boardlistpage">
      <div className="content">
        <div className="content__title">
          <h1>精算りれき</h1>
          <Button
            size='midium'
            text={'新しくつくる'}
            onClick={() => onCreateTrip()}
          />
        </div>
        <div id="list_board">
          {(assets || []).map((asset, index) => {
            return (
              <BoardlistItem
                key={index}
                asset={asset}
              />
            );
          })}
        </div>
      </div>
    </div>

export default BoardlistPageBase(BoardlistPage);
