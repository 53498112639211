// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Base of Footers

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Components
import Button from '../common/Button';

// Selectors
import { getMembers } from '../../selectors/member';

// SASS
import '../../constants/css/common/Footer.sass';

// Routes
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';


const MemberFooter = ({ members, match }) =>
  <div id="footer">
    <div className="footer__contents">
      <div className="footer__prices">
        <span id="footer__price--total">人数：{ members.length }</span>
      </div>
      <Link to={routes.TRIP_ACCESS + match.params.id}>
        <Button
          size='large'
          text='戻る'
          onClick={() => null}
        />
      </Link>
    </div>
  </div>


const mapStateToProps = state =>({
  members: getMembers(state),
});


export default compose(
  withRouter,
  connect(
    mapStateToProps
  ),
)(MemberFooter);
