// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Base of Footers

import React from 'react';
import { connect } from 'react-redux';

// Components
import Button from './Button';

// Selectors
import { getTotalPrice, getAveragePrice } from '../../selectors/receipt';
import { getMembers } from '../../selectors/member';

// SASS
import '../../constants/css/common/Footer.sass';

// Routes
import { Link } from 'react-router-dom';


const FooterBase = ({ totalPrice, averagePrice, route, text }) =>
  <div id="footer">
    <div className="footer__contents">
      <div className="footer__prices">
        <span id="footer__price--total">合計金額：{ totalPrice }円</span>
        <span id="footer__price--average">（一人あたり：{ averagePrice }円）</span>
      </div>
      <Link to={route}>
        <Button
          size='large'
          text={text}
          onClick={() => null}
        />
      </Link>
    </div>
  </div>


const mapStateToProps = state =>({
  totalPrice: getTotalPrice(state),
  averagePrice: getAveragePrice(state),
  members: getMembers(state),
});

export default connect(
  mapStateToProps
)(FooterBase);
