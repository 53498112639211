// Author Tatsuya Tsubakimoto. Created 2020/08/30
// Page to manage member data

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Components
import MemberListItem from './MemberListItem';
import MemberFooter from './MemberFooter';
import Button from '../common/Button';

// Selectors
import { getMembers } from '../../selectors/member';

// SASS
import '../../constants/css/MembersPage.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// HOC
import { withMemberData } from '../DBAccess';


const MembersPage = ({ members, onCreateMember, onEditMember, onRemoveMember}) => (
  <div className="members_page">
    <div className="wrapper">
      <div className="contents">
        <h1>メンバー</h1>
        <div id="list_member">
          {(members || []).map((member, index) => {
            return (
              <MemberListItem
                key={index}
                member={member}
                onEditMember={onEditMember}
                onRemoveMember={onRemoveMember}
              />
            );
          })}
          <Button
            size='large'
            text={<FontAwesomeIcon icon="plus" />}
            onClick={() => onCreateMember()}
          />
        </div>
      </div>
      <MemberFooter />
    </div>
  </div>
);


const mapStateToProps = state => ({
  members: getMembers(state),
});


export default compose(
  withMemberData,
  connect(
    mapStateToProps,
  ),
)(MembersPage);
